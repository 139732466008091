import { default as _5_45month_45cart_45offer32Qb2U5xywMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/5-month-cart-offer.vue?macro=true";
import { default as _6_45month_45half_45priceKO4mve819EMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/6-month-half-price.vue?macro=true";
import { default as aboutr0qO3J1HKiMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/about.vue?macro=true";
import { default as billingShlSzUMEUNMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/account/billing.vue?macro=true";
import { default as indexfRROQuw433Meta } from "/codebuild/output/src737028145/src/zeronet-web/pages/account/index.vue?macro=true";
import { default as profileZaPC0DubROMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/account/profile.vue?macro=true";
import { default as accountrtLLrh1ZgFMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/account.vue?macro=true";
import { default as authorizeJlpir2GNMIMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/authorize.vue?macro=true";
import { default as bbc_45promonnOO5OBqIMMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/bbc-promo.vue?macro=true";
import { default as chatd7gcVG2TKYMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/chat.vue?macro=true";
import { default as compass_45embedQmxGr1jZGlMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/compass-embed.vue?macro=true";
import { default as compassqS8Fk9IdwnMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/compass.vue?macro=true";
import { default as contactkujUGAkeVkMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/contact.vue?macro=true";
import { default as hyperfibreIWZNpBKMmUMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/hyperfibre.vue?macro=true";
import { default as indexMSSovZvygPMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/index.vue?macro=true";
import { default as internet_45speed_45testYxaSv3KQSZMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/internet-speed-test.vue?macro=true";
import { default as loginJLNKj63IcgMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/login.vue?macro=true";
import { default as logoutsHVGWz7WDEMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/logout.vue?macro=true";
import { default as our_45plansIGjrUAfHNHMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/our-plans.vue?macro=true";
import { default as plansGGntXMThicMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/plans.vue?macro=true";
import { default as privacy0spff5KTc8Meta } from "/codebuild/output/src737028145/src/zeronet-web/pages/privacy.vue?macro=true";
import { default as promotions1kCtsAdUQ3Meta } from "/codebuild/output/src737028145/src/zeronet-web/pages/promotions.vue?macro=true";
import { default as refer_45a_45friendPwKmSYrhMvMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/refer-a-friend.vue?macro=true";
import { default as _1ohyutdpM4EMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/register/1.vue?macro=true";
import { default as _2ijCSijm1HeMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/register/2.vue?macro=true";
import { default as _35xFoEE5kcxMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/register/3.vue?macro=true";
import { default as _4LAt9zFnpjdMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/register/4.vue?macro=true";
import { default as _5Qhayf1eYSQMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/register/5.vue?macro=true";
import { default as indexEsLUi0ypuFMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/register/index.vue?macro=true";
import { default as success1Uk8MqJ90NMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/register/success.vue?macro=true";
import { default as registerksMVbdskpFMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/register.vue?macro=true";
import { default as simply_45broadbandis35BBM29OMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/simply-broadband.vue?macro=true";
import { default as studentcard_45exclusive_45offerdorZ4rbaYCMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/studentcard-exclusive-offer.vue?macro=true";
import { default as sustainability6Gj8CBzl8nMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/sustainability.vue?macro=true";
import { default as termsjO5aYU4F5hMeta } from "/codebuild/output/src737028145/src/zeronet-web/pages/terms.vue?macro=true";
export default [
  {
    name: _5_45month_45cart_45offer32Qb2U5xywMeta?.name ?? "5-month-cart-offer",
    path: _5_45month_45cart_45offer32Qb2U5xywMeta?.path ?? "/5-month-cart-offer",
    meta: _5_45month_45cart_45offer32Qb2U5xywMeta || {},
    alias: _5_45month_45cart_45offer32Qb2U5xywMeta?.alias || [],
    redirect: _5_45month_45cart_45offer32Qb2U5xywMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/5-month-cart-offer.vue").then(m => m.default || m)
  },
  {
    name: _6_45month_45half_45priceKO4mve819EMeta?.name ?? "6-month-half-price",
    path: _6_45month_45half_45priceKO4mve819EMeta?.path ?? "/6-month-half-price",
    meta: _6_45month_45half_45priceKO4mve819EMeta || {},
    alias: _6_45month_45half_45priceKO4mve819EMeta?.alias || [],
    redirect: _6_45month_45half_45priceKO4mve819EMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/6-month-half-price.vue").then(m => m.default || m)
  },
  {
    name: aboutr0qO3J1HKiMeta?.name ?? "about",
    path: aboutr0qO3J1HKiMeta?.path ?? "/about",
    meta: aboutr0qO3J1HKiMeta || {},
    alias: aboutr0qO3J1HKiMeta?.alias || [],
    redirect: aboutr0qO3J1HKiMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/about.vue").then(m => m.default || m)
  },
  {
    path: accountrtLLrh1ZgFMeta?.path ?? "/account",
    children: [
  {
    name: billingShlSzUMEUNMeta?.name ?? "account-billing",
    path: billingShlSzUMEUNMeta?.path ?? "billing",
    meta: billingShlSzUMEUNMeta || {},
    alias: billingShlSzUMEUNMeta?.alias || [],
    redirect: billingShlSzUMEUNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/account/billing.vue").then(m => m.default || m)
  },
  {
    name: indexfRROQuw433Meta?.name ?? "account",
    path: indexfRROQuw433Meta?.path ?? "",
    meta: indexfRROQuw433Meta || {},
    alias: indexfRROQuw433Meta?.alias || [],
    redirect: indexfRROQuw433Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: profileZaPC0DubROMeta?.name ?? "account-profile",
    path: profileZaPC0DubROMeta?.path ?? "profile",
    meta: profileZaPC0DubROMeta || {},
    alias: profileZaPC0DubROMeta?.alias || [],
    redirect: profileZaPC0DubROMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/account/profile.vue").then(m => m.default || m)
  }
],
    name: accountrtLLrh1ZgFMeta?.name ?? undefined,
    meta: accountrtLLrh1ZgFMeta || {},
    alias: accountrtLLrh1ZgFMeta?.alias || [],
    redirect: accountrtLLrh1ZgFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/account.vue").then(m => m.default || m)
  },
  {
    name: authorizeJlpir2GNMIMeta?.name ?? "authorize",
    path: authorizeJlpir2GNMIMeta?.path ?? "/authorize",
    meta: authorizeJlpir2GNMIMeta || {},
    alias: authorizeJlpir2GNMIMeta?.alias || [],
    redirect: authorizeJlpir2GNMIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/authorize.vue").then(m => m.default || m)
  },
  {
    name: bbc_45promonnOO5OBqIMMeta?.name ?? "bbc-promo",
    path: bbc_45promonnOO5OBqIMMeta?.path ?? "/bbc-promo",
    meta: bbc_45promonnOO5OBqIMMeta || {},
    alias: bbc_45promonnOO5OBqIMMeta?.alias || [],
    redirect: bbc_45promonnOO5OBqIMMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/bbc-promo.vue").then(m => m.default || m)
  },
  {
    name: chatd7gcVG2TKYMeta?.name ?? "chat",
    path: chatd7gcVG2TKYMeta?.path ?? "/chat",
    meta: chatd7gcVG2TKYMeta || {},
    alias: chatd7gcVG2TKYMeta?.alias || [],
    redirect: chatd7gcVG2TKYMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: compass_45embedQmxGr1jZGlMeta?.name ?? "compass-embed",
    path: compass_45embedQmxGr1jZGlMeta?.path ?? "/compass-embed",
    meta: compass_45embedQmxGr1jZGlMeta || {},
    alias: compass_45embedQmxGr1jZGlMeta?.alias || [],
    redirect: compass_45embedQmxGr1jZGlMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/compass-embed.vue").then(m => m.default || m)
  },
  {
    name: compassqS8Fk9IdwnMeta?.name ?? "compass",
    path: compassqS8Fk9IdwnMeta?.path ?? "/compass",
    meta: compassqS8Fk9IdwnMeta || {},
    alias: compassqS8Fk9IdwnMeta?.alias || [],
    redirect: compassqS8Fk9IdwnMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/compass.vue").then(m => m.default || m)
  },
  {
    name: contactkujUGAkeVkMeta?.name ?? "contact",
    path: contactkujUGAkeVkMeta?.path ?? "/contact",
    meta: contactkujUGAkeVkMeta || {},
    alias: contactkujUGAkeVkMeta?.alias || [],
    redirect: contactkujUGAkeVkMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: hyperfibreIWZNpBKMmUMeta?.name ?? "hyperfibre",
    path: hyperfibreIWZNpBKMmUMeta?.path ?? "/hyperfibre",
    meta: hyperfibreIWZNpBKMmUMeta || {},
    alias: hyperfibreIWZNpBKMmUMeta?.alias || [],
    redirect: hyperfibreIWZNpBKMmUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/hyperfibre.vue").then(m => m.default || m)
  },
  {
    name: indexMSSovZvygPMeta?.name ?? "index",
    path: indexMSSovZvygPMeta?.path ?? "/",
    meta: indexMSSovZvygPMeta || {},
    alias: indexMSSovZvygPMeta?.alias || [],
    redirect: indexMSSovZvygPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: internet_45speed_45testYxaSv3KQSZMeta?.name ?? "internet-speed-test",
    path: internet_45speed_45testYxaSv3KQSZMeta?.path ?? "/internet-speed-test",
    meta: internet_45speed_45testYxaSv3KQSZMeta || {},
    alias: internet_45speed_45testYxaSv3KQSZMeta?.alias || [],
    redirect: internet_45speed_45testYxaSv3KQSZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/internet-speed-test.vue").then(m => m.default || m)
  },
  {
    name: loginJLNKj63IcgMeta?.name ?? "login",
    path: loginJLNKj63IcgMeta?.path ?? "/login",
    meta: loginJLNKj63IcgMeta || {},
    alias: loginJLNKj63IcgMeta?.alias || [],
    redirect: loginJLNKj63IcgMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutsHVGWz7WDEMeta?.name ?? "logout",
    path: logoutsHVGWz7WDEMeta?.path ?? "/logout",
    meta: logoutsHVGWz7WDEMeta || {},
    alias: logoutsHVGWz7WDEMeta?.alias || [],
    redirect: logoutsHVGWz7WDEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: our_45plansIGjrUAfHNHMeta?.name ?? "our-plans",
    path: our_45plansIGjrUAfHNHMeta?.path ?? "/our-plans",
    meta: our_45plansIGjrUAfHNHMeta || {},
    alias: our_45plansIGjrUAfHNHMeta?.alias || [],
    redirect: our_45plansIGjrUAfHNHMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/our-plans.vue").then(m => m.default || m)
  },
  {
    name: plansGGntXMThicMeta?.name ?? "plans",
    path: plansGGntXMThicMeta?.path ?? "/plans",
    meta: plansGGntXMThicMeta || {},
    alias: plansGGntXMThicMeta?.alias || [],
    redirect: plansGGntXMThicMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/plans.vue").then(m => m.default || m)
  },
  {
    name: privacy0spff5KTc8Meta?.name ?? "privacy",
    path: privacy0spff5KTc8Meta?.path ?? "/privacy",
    meta: privacy0spff5KTc8Meta || {},
    alias: privacy0spff5KTc8Meta?.alias || [],
    redirect: privacy0spff5KTc8Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: promotions1kCtsAdUQ3Meta?.name ?? "promotions",
    path: promotions1kCtsAdUQ3Meta?.path ?? "/promotions",
    meta: promotions1kCtsAdUQ3Meta || {},
    alias: promotions1kCtsAdUQ3Meta?.alias || [],
    redirect: promotions1kCtsAdUQ3Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/promotions.vue").then(m => m.default || m)
  },
  {
    name: refer_45a_45friendPwKmSYrhMvMeta?.name ?? "refer-a-friend",
    path: refer_45a_45friendPwKmSYrhMvMeta?.path ?? "/refer-a-friend",
    meta: refer_45a_45friendPwKmSYrhMvMeta || {},
    alias: refer_45a_45friendPwKmSYrhMvMeta?.alias || [],
    redirect: refer_45a_45friendPwKmSYrhMvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/refer-a-friend.vue").then(m => m.default || m)
  },
  {
    path: registerksMVbdskpFMeta?.path ?? "/register",
    children: [
  {
    name: _1ohyutdpM4EMeta?.name ?? "register-1",
    path: _1ohyutdpM4EMeta?.path ?? "1",
    meta: _1ohyutdpM4EMeta || {},
    alias: _1ohyutdpM4EMeta?.alias || [],
    redirect: _1ohyutdpM4EMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/register/1.vue").then(m => m.default || m)
  },
  {
    name: _2ijCSijm1HeMeta?.name ?? "register-2",
    path: _2ijCSijm1HeMeta?.path ?? "2",
    meta: _2ijCSijm1HeMeta || {},
    alias: _2ijCSijm1HeMeta?.alias || [],
    redirect: _2ijCSijm1HeMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/register/2.vue").then(m => m.default || m)
  },
  {
    name: _35xFoEE5kcxMeta?.name ?? "register-3",
    path: _35xFoEE5kcxMeta?.path ?? "3",
    meta: _35xFoEE5kcxMeta || {},
    alias: _35xFoEE5kcxMeta?.alias || [],
    redirect: _35xFoEE5kcxMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/register/3.vue").then(m => m.default || m)
  },
  {
    name: _4LAt9zFnpjdMeta?.name ?? "register-4",
    path: _4LAt9zFnpjdMeta?.path ?? "4",
    meta: _4LAt9zFnpjdMeta || {},
    alias: _4LAt9zFnpjdMeta?.alias || [],
    redirect: _4LAt9zFnpjdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/register/4.vue").then(m => m.default || m)
  },
  {
    name: _5Qhayf1eYSQMeta?.name ?? "register-5",
    path: _5Qhayf1eYSQMeta?.path ?? "5",
    meta: _5Qhayf1eYSQMeta || {},
    alias: _5Qhayf1eYSQMeta?.alias || [],
    redirect: _5Qhayf1eYSQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/register/5.vue").then(m => m.default || m)
  },
  {
    name: indexEsLUi0ypuFMeta?.name ?? "register",
    path: indexEsLUi0ypuFMeta?.path ?? "",
    meta: indexEsLUi0ypuFMeta || {},
    alias: indexEsLUi0ypuFMeta?.alias || [],
    redirect: indexEsLUi0ypuFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: success1Uk8MqJ90NMeta?.name ?? "register-success",
    path: success1Uk8MqJ90NMeta?.path ?? "success",
    meta: success1Uk8MqJ90NMeta || {},
    alias: success1Uk8MqJ90NMeta?.alias || [],
    redirect: success1Uk8MqJ90NMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/register/success.vue").then(m => m.default || m)
  }
],
    name: registerksMVbdskpFMeta?.name ?? undefined,
    meta: registerksMVbdskpFMeta || {},
    alias: registerksMVbdskpFMeta?.alias || [],
    redirect: registerksMVbdskpFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/register.vue").then(m => m.default || m)
  },
  {
    name: simply_45broadbandis35BBM29OMeta?.name ?? "simply-broadband",
    path: simply_45broadbandis35BBM29OMeta?.path ?? "/simply-broadband",
    meta: simply_45broadbandis35BBM29OMeta || {},
    alias: simply_45broadbandis35BBM29OMeta?.alias || [],
    redirect: simply_45broadbandis35BBM29OMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/simply-broadband.vue").then(m => m.default || m)
  },
  {
    name: studentcard_45exclusive_45offerdorZ4rbaYCMeta?.name ?? "studentcard-exclusive-offer",
    path: studentcard_45exclusive_45offerdorZ4rbaYCMeta?.path ?? "/studentcard-exclusive-offer",
    meta: studentcard_45exclusive_45offerdorZ4rbaYCMeta || {},
    alias: studentcard_45exclusive_45offerdorZ4rbaYCMeta?.alias || [],
    redirect: studentcard_45exclusive_45offerdorZ4rbaYCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/studentcard-exclusive-offer.vue").then(m => m.default || m)
  },
  {
    name: sustainability6Gj8CBzl8nMeta?.name ?? "sustainability",
    path: sustainability6Gj8CBzl8nMeta?.path ?? "/sustainability",
    meta: sustainability6Gj8CBzl8nMeta || {},
    alias: sustainability6Gj8CBzl8nMeta?.alias || [],
    redirect: sustainability6Gj8CBzl8nMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: termsjO5aYU4F5hMeta?.name ?? "terms",
    path: termsjO5aYU4F5hMeta?.path ?? "/terms",
    meta: termsjO5aYU4F5hMeta || {},
    alias: termsjO5aYU4F5hMeta?.alias || [],
    redirect: termsjO5aYU4F5hMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src737028145/src/zeronet-web/pages/terms.vue").then(m => m.default || m)
  }
]